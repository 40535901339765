@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.SPONSERS-HEADING {
  text-align: center;
  margin-top: 30px;
}

.SPONSERS-MAIN-CONTAINER {
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  /* width: 100vw; */
}

.SPONSERS-HEADING {
  font-size: 40px;
  font-family: "Playfair Display", serif;
}

.SPONSER-CONTAINER {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: center;
  margin: 30px;
}
.logo-slider {
  --image-size: 100px;
  border: 1px solid #575757;
  padding: 20px;
  overflow: hidden;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
.logo-slider:hover div {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.logo-slider div {
  display: flex;
  position: relative;
  -webkit-animation: marquee 12s linear infinite;
          animation: marquee 12s linear infinite;
  justify-content: space-around;
}
.logo-slider img {
  display: block;
  min-width: var(--image-size);
  height: var(--image-size);
  margin: 0 1vw;
}
.logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
@media (max-width: 900px) {
  .logo-slider {
    --image-size: 50px;
    --image-size: min(max(50px, 10vw), 100px);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}/*# sourceMappingURL=style.css.map */