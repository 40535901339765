@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey&display=swap");

* {
  box-sizing: border-box;
}
.ABOUT-US-MAIN-CONTAINER {
  background-color: white;
  width: 100vw;
  position: relative;
  /* border: 1px solid white; */
  /* background: rgba(0,0, 0, 0.2); */
}

.ABOUT-US-HEADING {
  text-align: center;
  font-size: 32px;
  color: #9bb44d;
  font-family: "Syncopate", sans-serif;
  margin: 60px 0 0 12px;
}

.aboutrightcontainer {
  box-sizing: border-box;
  display: flex;
  /* place-items: center ; */
  justify-content: center;
  align-items: center;
}

.aboutrightcontent {
  margin-top: 10px;
  width: 1500px;
  height: fit-content;
  padding: 30px 60px;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 20px;
  font-family: "Arapey", serif;
  font-weight: 600;
  /* text-shadow: 0px 1px 3px black; */
  letter-spacing: 2px;
  word-spacing: 2px;
  line-height: 26px;
  color: #0e491e;
}

.aboutrightcontent p:nth-child(even) {
  margin-top: 15px;
  padding: 20px 0 0 0;
}
/* --------------responsive------------- */

@media (max-width: 800px) {
  .ABOUT-US-MAIN-CONTAINER {
    background-color: white;
    width: 100vw;
    height: 100%;
    position: relative;
    /* border: 1px solid white; */
    /* background: rgba(0,0, 0, 0.2); */
  }

  .ABOUT-US-HEADING {
    text-align: center;
    font-size: 23px;
    color: #9bb44d;
    font-family: "Syncopate", sans-serif;
    margin: 60px 10px 0 8px;
  }

  .aboutrightcontainer {
    box-sizing: border-box;
    display: flex;
    /* place-items: center ; */
    justify-content: center;
    align-items: center;
  }

  .aboutrightcontent {
    margin-top: 8px;
    width: 100%;
    height: fit-content;
    padding: 25px 50px;
    border-radius: 8px;
    margin-right: 5px;
    font-size: 20px;
    font-family: "Arapey", serif;
    font-weight: 600;
    /* text-shadow: 0px 1px 3px black; */
    letter-spacing: 2px;
    word-spacing: 2px;
    line-height: 26px;
    color: #0e491e;
  }

  .aboutrightcontent p:nth-child(even) {
    margin-top: 15px;
    padding: 20px 0 0 0;
  }
}
