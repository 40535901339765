@import url("https://fonts.googleapis.com/css2?family=Arapey&family=Syncopate&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Vinyl&display=swap");

.EVENTS_CONTAINER {
  background-color: #f9efd7;
  /* background-color: #f0c3c2; */
  width: 100vw;
}

.EVENTS_HEADER_MAIN {
  padding-top: 30px;
  color: black;
  text-align: center;
  font-family: "Syncopate", sans-serif;
}

.EVENTS_HEADING_MAIN {
  margin-top: 10px;
  text-align: center;
  color: #8e56dc;
  text-transform: capitalize;
  font-weight: 800;
}

.EVENTS_FLEX_CONTAINER {
  display: flex;
  margin-top: 20px;
  width: 100vw;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 15% 0 15%;
  justify-content: space-evenly;
  align-items: center;
}

.EVENT-ITEM-CONTAINER {
  height: 300px;
  width: 300px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 15px solid #004e49;
  background-color: #f9efd7;
  border-radius: 15px;
  margin: 35px 25px;
  transition: all 0.2s ease-in-out;
}

.row-1,
.row-3 {
  border-color: #004e49;
}

.row-2 {
  border-color: #9bb44d;
}

.EVENT-ITEM-CONTAINER:hover {
  transform: scale(1.04);
}

.EVENT-ITEM-CONTAINER > img {
  border-radius: 20px;
  height: 100px;
  width: 100px;
}

.EVENT-ITEM-CONTAINER > p {
  margin-top: 65px;
  color: #9bb44d;
  font-family: "ubuntu", cursive;
}

body {
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  .EVENT-ITEM-CONTAINER {
    height: 250px;
    width: 250px;
  }
}
