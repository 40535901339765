@import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey&display=swap');


*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.HOMEPAGE-MAIN-CONTAINER {
    width: 100vw;
    background-color: rgba(249, 239, 215, 0.781);
    background-image: url("../../../public/assets/hero2.png");
    height: 883px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}


.headerline{
    /* min-height: 800px; */
    text-align: center;
    position: absolute;
    top: 20%;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    align-items: center;
    /* border: 2px solid white; */
    /* transform: translate(-50%,-50%); */
}

.headerline h1{
    font-family: 'Syncopate', sans-serif;
    color: #9BB44D;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 100px;
     line-height: 120px;
}
    span{
        color: #004E49;
    }
     .headerline >  p {
     font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 161.52%; */
    /* border: 2px dashed #9BB44D; */
    width: 679px ;
    height: 156px;
    text-align: center;
    text-transform: capitalize;
    font-family: 'Arapey', serif;
    line-height: 165.552%;
    margin-left: 15%;
    margin-top: 60px;
}

.headerline h2{
    text-transform: capitalize;
    font-family: 'Syncopate', sans-serif;
    color: #004E49;
    font-size: 23px;
    /* font-weight: 800; */
}

 .headerline .launch h1{
    position: absolute;
    top: 616px;
    color: #004E49;
    font-weight: 800;
    width: max-content;
    margin-left: 10%;
}

.expbtn{
    width: 278px;
    height: 60px;
    z-index: 8888;
    border:  2px solid black;
    
    background-color: #004E49;
    /* color: white; */
    box-shadow: 2px 5px  black;
    border-radius: 10px;
    position: relative;
    
    
}

.expbtn > a {
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Syncopate', sans-serif;
    text-transform: capitalize;
}
 



@media (max-width:800px)
{
    .HOMEPAGE-MAIN-CONTAINER {
        width: 100%;
        background-color: rgba(249, 239, 215, 0.781);
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        /* background: url('assets\hero1.png') ; */
        overflow: hidden;
    }
    .bgimage{
        object-fit: contain;
        background-position: center center;
    
    }
    
    .headerline{
        /* min-height: 800px; */
        text-align: center;
        position: absolute;
        top: 20%;
        align-items: center;
        
    }
    
    .headerline h1{
        font-family: 'Syncopate', sans-serif;
        color: #9BB44D;
        text-transform: capitalize;
        font-weight: 900;
        font-size: 40px;
         line-height: 120px;
    }
        span{
            color: #004E49;
        }
         .headerline >  p {
         font-style: normal;
        font-weight: 500;
        font-size: 20px;
        /* line-height: 161.52%; */
        /* border: 2px dashed #9BB44D; */
        width: 100% ;
        height: 200px;
        text-align: center;
        text-transform: capitalize;
        font-family: 'Arapey', serif;
        line-height: 165.552%;
        margin-left: 2px;

        margin-top: 60px;
    }
    
    .headerline h2{
        text-transform: capitalize;
        font-family: 'Syncopate', sans-serif;
        color: #004E49;
        font-size: 20px;
        /* font-weight: 800; */
    }
    
     .headerline .launch h1{
        position: absolute;
        top: 616px;
        color: #004E49;
        font-weight: 800;
        width: max-content;
        margin-left: 10%;
    }
    
    .expbtn{
        width: 250px;
        height: 55px;
        z-index: 8888;
        border:  2px solid black;
        
        background-color: #004E49;
        /* color: white; */
        box-shadow: 2px 5px  black;
        border-radius: 10px;
        position: relative;
        
        
    }
    
    .expbtn > a {
        color: white;
        text-decoration: none;
        font-size: 20px;
        font-weight: bolder;
        font-family: 'Syncopate', sans-serif;
        text-transform: capitalize;
    }
     
    
    

}