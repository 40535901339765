.FOOTER-MAIN-CONTAINER {
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.EVENT-HEADING,
.EVENT-DESCRIPTION {
    text-align: center;
}

.EVENT-HEADING {
    padding: 0px;
    margin: 0px;
    font-size: 70px;
}

.EVENT-DESCRIPTION {
    margin: 0px;
    font-size: 40px;
}

.NAME-LOCATION-PART-FOLLOWING {
    display: flex;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
    justify-content: space-evenly;
}


.SOCIAL-MEDIA-ICONS,
.LOCATION-ICON {
    height: 60px;
    width: 60px;
}

@media (max-width:674px) {
    .LOCATION-ADDRESS {
        display: block;
        width: 100vw;
        overflow-x: hidden;
    }

    .FOLLOWING {
        display: block;
        width: 100vw;
        overflow-x: hidden;
    }
}

.LOCATION-ADDRESS {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}